import Image from 'next/image'
import React from 'react'
import logResponsive from '/public/logos/logo-icon-blue.png'
import logo from '/public/logos/logo.png'

type IProps = {
  defer?: boolean
}

const SpotMeLogo = ({ defer }: IProps) => {
  return (
    <div className="relative flex w-[36px] sm:mb-2 sm:w-[150px]">
      <Image
        quality={100}
        className="hidden sm:block"
        priority={!defer}
        src={logo}
        alt="Logotipo SpotMe, la plataforma que conecta personas que quieren rentar una bodega o rentar un cajón de estacionamiento con personas que ofrecen un espacio disponible de almacenamiento y quieren generar ingresos pasivos con sus espacios disponibles."
        sizes="(max-width: 768px) 30vw,
                20vw"
        style={{ objectFit: 'contain' }}
      />
      <Image
        quality={100}
        priority={!defer}
        className="block sm:hidden"
        src={logResponsive}
        alt="Logotipo SpotMe, la plataforma que conecta personas que quieren rentar una bodega o rentar un cajón de estacionamiento con personas que ofrecen un espacio disponible de almacenamiento y quieren generar ingresos pasivos con sus espacios disponibles."
        style={{ objectFit: 'contain' }}
      />
    </div>
  )
}

export default React.memo(SpotMeLogo)
