import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import React, { useState } from 'react'
import { Colors, FontWeight } from 'src/constants/theme'
import { IGoogleAutocompleteSuggestion } from 'src/types/graphql'
import { RedirectUrls } from '../../types/types'
import BaseButton from '../Buttons/BaseButton'
import SpotMeLogo from '../SpotMeLogo'

const CloseIcon = dynamic(() => import('@mui/icons-material/Close'))
const MenuIcon = dynamic(() => import('@mui/icons-material/Menu'))
const AppBar = dynamic(() => import('@mui/material/AppBar'))
const Toolbar = dynamic(() => import('@mui/material/Toolbar'))
const BaseIconButton = dynamic(() => import('../Buttons/BaseIconButton'))
const SearchIconAutoComplete = dynamic(() => import('./NavBarSearch'))
const DesktopButtons = dynamic(() => import('./DesktopButtons'), {
  ssr: false,
  loading: () => <div className="h-full w-[500px]" />
})

const StyledText = styled(Typography)`
  && {
    color: ${Colors.black400};
    font-size: 15px;
    font-weight: ${FontWeight.regular};
    white-space: nowrap;
  }
`

const Drawer = dynamic(() => import('./Drawer'), { ssr: false })
const Collapse = dynamic(() => import('@mui/material/Collapse'), { ssr: false })
const LandingNotificationsWrapper = dynamic(
  () => import('./LandingNotificationsWrapper'),
  { ssr: false }
)

type IProp = {
  showSearch?: boolean
  transparent?: boolean
  noShadow?: boolean
  secondRow?: React.ReactNode
  isAnfitrion?: boolean
  handleOnSearch?: (
    place: IGoogleAutocompleteSuggestion,
    search: string
  ) => void
}

const LandingNav = ({
  showSearch,
  transparent,
  noShadow,
  secondRow,
  isAnfitrion,
  handleOnSearch
}: IProp) => {
  const { t } = useTranslation('nav')
  const [isOpen, setIsOpen] = useState(false)
  const trigger = useScrollTrigger({ threshold: 10 })
  const hideElevation = transparent || !trigger || isOpen || noShadow

  return (
    <AppBar
      className="px-5 py-[19px] md:px-[50px]"
      position="fixed"
      sx={{
        bgcolor: 'lightest.main',
        ...(transparent && !isOpen && { background: 'transparent' }),
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderBottom: hideElevation ? 'none' : `1px solid ${Colors.grey300}`
      }}
      elevation={0}
    >
      <Toolbar disableGutters className="min-h-min justify-between md:h-[50px]">
        <div className="flex">
          <Link href="/" passHref>
            <SpotMeLogo />
          </Link>
        </div>

        {showSearch && (
          <SearchIconAutoComplete handleOnSearch={handleOnSearch} />
        )}
        {!showSearch && (
          <div className="hidden items-center justify-center gap-2 md:flex">
            <BaseButton
              href={RedirectUrls.Search}
              rounded
              className="block md-xl:hidden"
            >
              <StyledText>{t('NAV.LINK_FIND')}</StyledText>
            </BaseButton>
            <BaseButton
              href={RedirectUrls.Broker}
              rounded
              className="block md-lg:hidden"
            >
              <StyledText>{t('NAV.BROKER')}</StyledText>
            </BaseButton>
            <BaseButton href={RedirectUrls.Help} prefetch={false} rounded>
              <StyledText>{t('NAV.LINK_HELP')}</StyledText>
            </BaseButton>
            <BaseButton href={RedirectUrls.Blog} prefetch={false} rounded>
              <StyledText>{t('NAV.LINK_BLOG')}</StyledText>
            </BaseButton>
            {/* <BaseButton
              href={RedirectUrls.Refer}
              rounded
              className="block md-lg:hidden"
            >
              <StyledText>{t('NAV.REFER')}</StyledText>
            </BaseButton> */}
          </div>
        )}
        <DesktopButtons hideLinks={showSearch} isAnfitrion={isAnfitrion} />
        <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />

        <Box sx={{ display: { md: 'none' }, position: 'relative' }}>
          <BaseIconButton
            onClick={() => setIsOpen(!isOpen)}
            size="large"
            aria-label="open drawer"
          >
            <>
              <Collapse
                in={isOpen}
                unmountOnExit
                sx={{ position: 'absolute', mr: 1, color: Colors.primary }}
              >
                <CloseIcon sx={{ width: 33, height: 33 }} />
              </Collapse>
              <Collapse
                in={!isOpen}
                unmountOnExit
                sx={{ position: 'absolute', mr: 1 }}
              >
                <LandingNotificationsWrapper position="new" />
                <MenuIcon
                  sx={{ width: 33, height: 33, color: Colors.black500 }}
                />
              </Collapse>
            </>
          </BaseIconButton>
        </Box>
      </Toolbar>
      <div className="md:mx-[-50px]">{secondRow}</div>
    </AppBar>
  )
}

export default React.memo(LandingNav)
